import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { ZabbixState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetMnemonics } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

type MainContext = ActionContext<ZabbixState, State>;

export const actions = {
    async actionGetMnemonics(context: MainContext) {
        try {
            const response = await api.getMnemonics(context.rootState.main.token);
            if (response) {
                commitSetMnemonics(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<ZabbixState, State>('');

export const dispatchGetMnemonics = dispatch(actions.actionGetMnemonics);
