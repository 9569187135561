import { ZabbixState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    mnemonics: (state: ZabbixState) => state.mnemonics,
};

const { read } = getStoreAccessors<ZabbixState, State>('');

export const readMnemonics = read(getters.mnemonics);
