// import { IMnemonic } from '@/interfaces';
import { ZabbixState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setMnemonics(state: ZabbixState, payload) {
        state.mnemonics = payload;
    },
};

const { commit } = getStoreAccessors<ZabbixState, State>('');

export const commitSetMnemonics = commit(mutations.setMnemonics);
